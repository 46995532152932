import {
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpInterceptor,
    HttpInterceptorFn,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HealthCheckService } from '../services/health-check.service';

@Injectable()
export class HealthCheckInterceptor implements HttpInterceptor {
    private readonly healthCheckService = inject(HealthCheckService);

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap((event) => {
                if (event.type === HttpEventType.Response) {
                    this.healthCheckService.setOutOfService(false);
                }
            }),
            catchError((errorResponse) => {
                this.serverOutOfService(errorResponse);
                return throwError(() => errorResponse);
            }),
        );
    }

    private serverOutOfService(errorResponse: HttpErrorResponse) {
        if (errorResponse.status === 503) {
            this.healthCheckService.setOutOfService(true);
        }
    }
}

export const healthCheckInterceptor: HttpInterceptorFn = (req, next) => {
    return inject(HealthCheckInterceptor).intercept(req, { handle: next });
};

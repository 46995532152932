import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { ResizeElementService } from '@interacta-shared/ui';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[injShowMoreDetect]',
})
export class ShowMoreDetectDirective implements AfterViewInit, OnDestroy {
    @Input() className: string; // nome della classe degli elementi figli del container
    @Input() offsetButton: number;

    @Output() hiddenElements = new EventEmitter<HTMLElement[]>();

    private containerMutationObserver: MutationObserver;
    private destroy$ = new Subject<void>();

    constructor(
        private element: ElementRef<HTMLElement>,
        private resizeElementService: ResizeElementService,
    ) {}

    detectContainerResize(): void {
        this.resizeElementService
            .observeElement(this.element.nativeElement)
            .pipe(takeUntil(this.destroy$))
            .subscribe((_) => this.emitHiddenElements());
    }

    detectDomChange(): void {
        const element = this.element.nativeElement;
        this.containerMutationObserver = new MutationObserver(
            (mutations: MutationRecord[]) => {
                mutations.forEach(() => {
                    this.emitHiddenElements();
                });
            },
        );
        // mi metto in ascolto di modifiche sui figli/subtree del container
        this.containerMutationObserver.observe(element, {
            childList: true,
            subtree: true,
        });
    }

    emitHiddenElements(): void {
        const elements = Array.from(
            this.element.nativeElement.getElementsByClassName(this.className),
        ) as HTMLElement[];

        if (!elements) {
            return;
        }

        const containerWidth =
            this.element.nativeElement.clientWidth - this.offsetButton;
        const hiddenElements = [];
        let accumulatedWidth = 0;

        for (const item of elements) {
            const itemWidth = item.offsetWidth || item.clientWidth;
            accumulatedWidth += itemWidth;

            if (containerWidth < accumulatedWidth) {
                hiddenElements.push(item);
                item.style.visibility = 'hidden';
                item.style.position = 'absolute';
            } else {
                item.style.visibility = 'visible';
                item.style.position = '';
            }
        }

        this.hiddenElements.emit(hiddenElements);
    }

    ngAfterViewInit(): void {
        this.emitHiddenElements();
        this.detectDomChange();
        this.detectContainerResize();
    }

    ngOnDestroy(): void {
        this.containerMutationObserver.disconnect();
        this.destroy$.next();
    }
}

import {
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
    BuildInfo,
    ConfigurationService,
} from '@interacta-shared/data-access-configuration';
import { Observable } from 'rxjs';

const EXCLUDED_PATHS: string[] = [];

function isExcludedPath(url: string): boolean {
    return EXCLUDED_PATHS.some((path) => url.includes(path));
}

const INTERACTA_USER_AGENT_HEADER_NAME = 'Interacta-User-Agent';

function platform(): string | undefined {
    const userAgent = navigator?.userAgent;
    if (userAgent) {
        const spaceIdx = userAgent.indexOf(' ');
        const commaIdx = userAgent.indexOf(',');
        return userAgent.slice(0, Math.min(spaceIdx, commaIdx));
    }
    return undefined;
}

function buildInteractaUserAgent(
    buildInfo: BuildInfo | undefined,
): string | undefined {
    return buildInfo
        ? `client=interacta_web, version=${
              buildInfo.version
          }, platform=${platform()}, hash=${buildInfo.hash}`
        : undefined;
}

@Injectable()
export class UserAgentInterceptor implements HttpInterceptor {
    private configurationService = inject(ConfigurationService);
    private _interactaUserAgent: string | undefined = undefined;
    private get interactaUserAgent(): string | undefined {
        if (!this._interactaUserAgent) {
            this._interactaUserAgent = buildInteractaUserAgent(
                this.configurationService.getEnvironmentInfo()?.buildInfo
                    .frontend,
            );
        }
        return this._interactaUserAgent;
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<
        | HttpSentEvent
        | HttpHeaderResponse
        | HttpProgressEvent
        | HttpResponse<any>
        | HttpUserEvent<any>
    > {
        const nextReq =
            isExcludedPath(req.url) || !this.interactaUserAgent
                ? req
                : req.clone({
                      headers: req.headers.set(
                          INTERACTA_USER_AGENT_HEADER_NAME,
                          this.interactaUserAgent,
                      ),
                  });

        return next.handle(nextReq);
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Member } from '../models/member/member.model';
import { wrapMembers } from '../models/member/member.utils';
import { IUsersGroup } from '../models/user-group.model';
import { IUser } from '../models/user.model';

@Pipe({
    name: 'wrapMembers',
})
export class WrapMembersPipe implements PipeTransform {
    transform(value: (IUser | IUsersGroup)[]): Member[] {
        return wrapMembers(value);
    }
}

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { inject, makeEnvironmentProviders } from '@angular/core';
import { Router } from '@angular/router';
import { HealthCheckInterceptor } from '../interceptors/health-check.interceptor';
import { HealthCheckService } from '../services/health-check.service';

/**
 *
 * @param outOfServicePath
 * @param standaloneInterceptors use false in legacy applications importing `HttpClientModule`, true in standalone applications using `provideHttpClient(withInterceptors(healthCheckInterceptor))`
 * @returns
 */
export const provideHealthCheck = (
    outOfServicePath: string,
    standaloneInterceptors = true,
) =>
    makeEnvironmentProviders([
        {
            provide: HealthCheckService,
            useFactory: () =>
                new HealthCheckService(inject(Router), outOfServicePath),
        },
        ...[
            standaloneInterceptors
                ? HealthCheckInterceptor
                : {
                      provide: HTTP_INTERCEPTORS,
                      useClass: HealthCheckInterceptor,
                      multi: true,
                  },
        ],
    ]);

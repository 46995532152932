import {
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const EXCLUDED_PATHS = ['assets'];

function isExcludedPath(url: string): boolean {
    return EXCLUDED_PATHS.some((path) => url.includes(path));
}
@Injectable()
export class HttpNoCacheInterceptorService implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<
        | HttpSentEvent
        | HttpHeaderResponse
        | HttpProgressEvent
        | HttpResponse<any>
        | HttpUserEvent<any>
    > {
        const nextReq = isExcludedPath(req.url)
            ? req
            : req.clone({
                  headers: req.headers
                      .set('Cache-Control', 'no-cache')
                      .set('Pragma', 'no-cache')
                      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
                      .set('If-Modified-Since', '0'),
              });

        return next.handle(nextReq);
    }
}

import {
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpInterceptor,
    HttpInterceptorFn,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigurationService } from '../services/configuration.service';

@Injectable()
export class BackendVersionCheckInterceptor implements HttpInterceptor {
    constructor(private configurationService: ConfigurationService) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler,
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap((event) => {
                if (event.type === HttpEventType.Response) {
                    this.checkBackendVersion(event);
                }
            }),
        );
    }

    private checkBackendVersion(response: HttpResponse<unknown>) {
        if (
            response.headers &&
            this.configurationService.getEnvironmentInfo()
        ) {
            const headerVersionBackend = response.headers.get(
                'interacta-backend-version',
            );
            const currentVersionBackend =
                this.configurationService.getEnvironmentInfo()?.buildInfo
                    .backend.version;

            if (
                headerVersionBackend &&
                currentVersionBackend &&
                headerVersionBackend !== currentVersionBackend
            ) {
                this.configurationService.setUpdateVersionRequired();
            }
        }
    }
}

export const backendVersionCheckInterceptor: HttpInterceptorFn = (
    req,
    next,
) => {
    return inject(BackendVersionCheckInterceptor).intercept(req, {
        handle: next,
    });
};

import { getLocaleNumberSymbol } from '@angular/common';
import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_LEGACY_INPUT_VALUE_ACCESSOR as MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/legacy-input';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';

@Directive({
    selector: '[injDecimalNumber]',
    providers: [
        {
            provide: MAT_INPUT_VALUE_ACCESSOR,
            useExisting: DecimalNumberDirective,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DecimalNumberDirective),
            multi: true,
        },
    ],
})
/**
 * La direttiva permette di gestire l'inserimento di numeri decimali gestiti da un parametro in ingresso,
 * e di visualizzare la stringa "internazionalizzata" con i separatori corretti (migliaia e decimali).
 * [Ad es. in locale 'it' -> '1.234,56'; in locale 'en' -> '1,234.56']
 *
 * Si comporta da provider per i matInput standard di tipo 'text', permettendo così un utilizzo semplificato della direttiva.
 *
 * Riceve e ritorna un 'value' (formControl) di tipo number.
 * Manipola il dato in formato stringa
 *
 * Source Doc = https://link.medium.com/ETzclj8184
 *
 * {@link decimal_digits} = numero di decimali da visualizzare
 *
 * Angelo Rubino
 */
export class DecimalNumberDirective implements OnInit, ControlValueAccessor {
    @Input('decimal_digits') decimal_digits = 2;
    private _value: number | null;

    locale: string;
    decimalSeparator = '.';
    groupSeparator: string;
    formatter: Intl.NumberFormat;

    constructor(
        private el: ElementRef<HTMLInputElement>,
        configService: ConfigurationService,
    ) {
        this.locale = configService.getCurrentLanguage().code || null;
        this.decimalSeparator = getLocaleNumberSymbol(this.locale, 0);
    }

    ngOnInit(): void {
        this.formatter = new Intl.NumberFormat(this.locale, {
            maximumFractionDigits: this.decimal_digits,
        });
        this.groupSeparator = this.getGroupSeparator();
    }

    private getGroupSeparator(): string {
        const testNumber = 1000;
        return this.getLocaleFormattedStringByValue(testNumber).substring(1, 2);
    }

    private getLocaleFormattedStringByValue(value: number): string {
        return this.formatter.format(value);
    }

    get value(): number | null {
        return this._value;
    }

    @Input('value')
    set value(value: number | null) {
        this._value = value;
        this.formatValue(value);
    }

    private formatValue(value: any) {
        if (value !== null) {
            this.el.nativeElement.value =
                this.getLocaleFormattedStringByValue(value);
        } else {
            this.el.nativeElement.value = '';
        }
    }

    private unFormatValue() {
        const value = this.el.nativeElement.value;
        if (value) {
            this.el.nativeElement.value = value.replace(
                new RegExp(`\\${this.groupSeparator}`, 'g'),
                '',
            );
        } else {
            this.el.nativeElement.value = '';
        }
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string) {
        if (!value) {
            this._value = null;
            this._onChange(this._value);
        } else if (this.check(value)) {
            const javascriptNumberStringValue = value.replace(
                this.decimalSeparator,
                '.',
            );
            this._value = parseFloat(javascriptNumberStringValue);
            this._onChange(this._value); // here to notify Angular Validators
        } else {
            this.formatValue(this._value);
            this.unFormatValue();
        }
    }

    @HostListener('blur')
    _onBlur() {
        this.formatValue(this._value); // add formatting pattern
    }

    @HostListener('focus')
    onFocus() {
        this.unFormatValue(); // remove group separator for editing purpose
    }

    _onChange(value: any): void {}

    writeValue(value: any) {
        this._value = value;
        this.formatValue(this._value); // format Value
    }

    registerOnChange(fn: (value: any) => void) {
        this._onChange = fn;
    }

    registerOnTouched() {}

    private check(value: string) {
        if (this.decimal_digits <= 0) {
            return String(value).match(new RegExp(/^\d+$/));
        } else {
            const regExpString = `^\\s*((\\d+(\\${this.decimalSeparator}\\d{0,${this.decimal_digits}})?)|
                        ((\\d*(\\${this.decimalSeparator}\\d{1,${this.decimal_digits}}))))\\s*$`;

            return String(value).match(new RegExp(regExpString));
        }
    }
}

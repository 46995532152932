import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { provideAuth } from '@interacta-shared/data-access-auth';
import { provideBackendVersionCheck } from '@interacta-shared/data-access-configuration';
import { provideHealthCheck } from '@interacta-shared/util-common';
import { AppRoutingService } from '@modules/app-routing/app-routing.service';
import { outOfService } from '@modules/app-routing/routes';
import {
    CurrentLanguageInterceptor,
    ExponentialBackoffInterceptor,
} from '@modules/core';
import { ShowMoreDetectDirective } from '@modules/core/directives/show-more-detect.directive';
import { MockHttpInterceptor } from '@modules/core/helpers/mock.interceptor';
import { HttpNoCacheInterceptorService } from '@modules/core/helpers/no-cache.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { DecimalNumberDirective } from './directives/decimal-number.directive';
import { FallbackImageDirective } from './directives/fallback-image.directive';
import { UserAgentInterceptor } from './helpers/user-agent.interceptor';
import { WrapMembersPipe } from './pipes/wrap-member.pipe';

const directives = [
    ShowMoreDetectDirective,
    FallbackImageDirective, // keep in core.module
    DecimalNumberDirective,
];
const pipes = [WrapMembersPipe];

@NgModule({
    imports: [HttpClientModule, TranslateModule, CommonModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ExponentialBackoffInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UserAgentInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpNoCacheInterceptorService,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MockHttpInterceptor,
            multi: true,
        },
        provideHealthCheck(`/${outOfService}`, false),
        provideAuth(AppRoutingService, false),
        provideBackendVersionCheck(false),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CurrentLanguageInterceptor,
            multi: true,
        },
    ],
    exports: [...directives, ...pipes],
    declarations: [...directives, ...pipes],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [{ provide: DateFnsConfigurationService }],
        };
    }
}

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { makeEnvironmentProviders } from '@angular/core';
import { BackendVersionCheckInterceptor } from '../interceptors/backend-version-check.interceptor';

/**
 *
 * @param standaloneInterceptors use false in legacy applications importing `HttpClientModule`, true in standalone applications using `provideHttpClient(withInterceptors(backendVersionCheckInterceptor))`
 * @returns
 */
export const provideBackendVersionCheck = (standaloneInterceptors = true) =>
    makeEnvironmentProviders([
        standaloneInterceptors
            ? BackendVersionCheckInterceptor
            : {
                  provide: HTTP_INTERCEPTORS,
                  useClass: BackendVersionCheckInterceptor,
                  multi: true,
              },
    ]);

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthRoutingService } from '@interacta-shared/data-access-auth';
import {
    login,
    loginExpiredSession,
    loginTerms,
    urlToAppRoute,
    verifyPrivateEmail,
} from './routes';

@Injectable({ providedIn: 'root' })
export class AppRoutingService extends AuthRoutingService {
    constructor(router: Router) {
        super(
            {
                loginUrl: `/${login}`,
                loginTermsUrl: `/${loginTerms}`,
                verifyPrivateEmailUrl: `/${verifyPrivateEmail}`,
                expiredSessionUrl: `/${loginExpiredSession}`,
                externalAuthRedirectUrl: '/external-auth-redirect',
            },
            router,
        );
    }

    isLoginPage(url: string): boolean {
        return urlToAppRoute(url) === login;
    }
    isTermsPage(url: string): boolean {
        return urlToAppRoute(url) === loginTerms;
    }
    isPrivateEmailPage(url: string): boolean {
        return urlToAppRoute(url) === verifyPrivateEmail;
    }
    isExpiredSessionPage(url: string): boolean {
        return urlToAppRoute(url) === loginExpiredSession;
    }
    isExternalAuthRedirectPage(url: string): boolean {
        return url.startsWith('/external-auth-redirect');
    }
}
